.onboarding
  background: linear-gradient(180deg, rgba(243, 111, 59, 0.05) 0%, rgba(0, 0, 0, 0.74) 100% ),linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100% ), url('../../../assets/img/onboarding-mobile.jpg') no-repeat
  background-size: cover
  background-position: center
  position: fixed
  top: 0
  left: 0
  bottom: 0
  right: 0
  display: flex
  flex-direction: column
  justify-content: flex-end
  align-items: center
  padding: 50px 15px

  button
    margin-top: 100px
  .onboard-socials
    svg
      fill: #fff
      border-color: #fff
      & + svg
        margin-left: 62px
      &:hover
        border-color: var(--main)
    p
      color: #fff
      font-size: 1.125rem
      text-align: center
      padding: 16px 0 24px

.onboarding-container
  display: flex
  flex-direction: column
  justify-content: flex-end
  align-items: center

@media (min-width: 576px)
  .onboarding
    background-image: linear-gradient( 180deg, rgba(243, 111, 59, 7.4e-5) 0%, rgba(0, 0, 0, 0.74) 100% ), linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100% ), url('../../../assets/img/onboarding-desktop.jpg')

  .onboarding button.button
    max-width: 256px

  .onboarding svg.logo
    width: 570px
    height: 100px

@media (min-width: 1024px)
  .onboarding button.button
    max-width: 400px
