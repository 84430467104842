.event-item {
  border-radius: 16px;
  position: relative;
  background: var(--gradientTransparent);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 24px;
}

.event-item * {
  font-family: 'Roboto', sans-serif;
}

.event-item .emoji {
  padding: 11px 19px 14px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  font-size: 32px;
}

.event-item .event-item-body {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background: rgba(255, 255, 255, 0.6);
  padding: 24px 15px;
  position: relative;
  overflow-wrap: break-word;
}
.event-item .event-item-body > * {
  line-height: 1;
}

.event-item .title {
  font-weight: 500;
  font-size: 18px;
  margin: 0;
  color: var(--textColorTitle);
}

.event-item .info {
  margin: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.event-item .info .info-item {
  padding-left: 24px;
  position: relative;
  color: var(--textColorTitle);
}

.event-item .info .info-item svg {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.event-item .info .info-item.count-down {
  color: var(--main);
}

.event-item-link {
  -webkit-touch-callout: none;
  -webkit-user-drag: none;
}

.event-item::before {
  position: absolute;
  content: '';
  top: -16px;
  left: -25px;
  background: var(--gradientSemiTransparent);
  filter: blur(80px);
  width: 50%;
  padding-bottom: 50%;
  border-radius: 50%;
  max-width: 180px;
  max-height: 180px;
  pointer-events: none;
  -webkit-transform: translateZ(0);
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
}

.event-item:nth-child(2n + 1)::before {
  top: -2px;
  left: auto;
  right: -4px;
}
.event-item .description {
  color: var(--textColor);
}

.event-item .byoc {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 16px;
  background: #876af9;
  padding: 6px 8px 5px 7px;
  font-family: 'Nunito';
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.event-item .byoc svg {
  margin-right: 6px;
}

.event-item .byoc.pending {
  background: #8b8b8b;
}
