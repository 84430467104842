
.loading-indicator.top {
  animation: loading-indicator-top 1s infinite linear;
  top: 36px;
  width: 40px;
  height: 40px;
  border-width: 6px;
  position: sticky;
  z-index: 100;
}

@keyframes loading-indicator-top {
  0% {
    transform: translateX(-50%) rotate(0);
  }
  100% {
      transform: translateX(-50%) rotate(360deg);
  } 
}