.list-sort select {
  padding: 4px 30px 4px 10px;
  border-bottom: none;
}

.list-sort .dropdown::before {
  top: 12px;
  width: 10px;
  height: 5px;
  background-image: url('../../../../img/icons/dropdown-arrow-gray.svg');
}
