
.profile-slider {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  border-radius: 6px;
}

.profile-slider .swiper-wrapper {
  position: absolute;
  height: 100%;
}

.profile-slider .swiper-slide img {
  width: 100%;
  height: 100%;
  min-height: 375px;
  object-fit: cover;
  object-position: center;
  border-radius: 6px;
}

.profile-slider .swiper-button-next,
.profile-slider .swiper-button-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  z-index: 2;
  border-radius: 50%;
  cursor: pointer;
  transition: .15s;
}

.profile-slider .swiper-button-disabled {
  opacity: 0;
  visibility: hidden;
}

.profile-slider .swiper-button-next:hover,
.profile-slider .swiper-button-prev:hover {
  background: #0005;
} 

.profile-slider .swiper-button-prev {
  left: 10px;
}
.profile-slider .swiper-button-next {
  right: 10px;
}

.profile-slider .swiper-button-next::before,
.profile-slider .swiper-button-prev::before {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url('../../../../img/icons/slider-arrow.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.profile-slider .swiper-button-next::before {
  transform: rotateY(180deg)
}