.image-select-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 24px;
}

.image-select-block .image-select:first-of-type {
  margin-bottom: 8px;
}

.image-select-block > label {
  /* display: none; */
  width: 100%;
  margin-bottom: 16px;
  font-weight: 700;
  color: var(--textColorSubtitle);
}