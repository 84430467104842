.icon
  display: flex
  width: 30px
  height: 30px
  justify-content: flex-start
  align-items: flex-end
  position: relative
  .unread
    position: absolute
    width: 7px
    height: 7px
    background: #FF1212
    top: 0
    right: 0
    border-radius: 50%
