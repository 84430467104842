.users {
  display: flex;
  margin-left: -16px;
  .placeholder {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
      linear-gradient(134.72deg, #c3ebf3 0%, #7a4dfa 101.5%);
    margin-left: 16px;
  }
}

.crew {
  margin-top: 45px;
}
