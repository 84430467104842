
.modal-overlay
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  background-color: var(--overlayBackgroundColor)
  display: flex
  align-items: center
  justify-content: center
  z-index: 200
  transition: .2s
  opacity: 0

.modal-overlay-after-open
  opacity: 1

.modal-overlay-before-close
  opacity: 0
  pointer-events: none

.modal
  background-color: #fff
  border-radius: 16px
  min-width: 290px
  max-width: 345px
  overflow: hidden
  position: relative
  &:focus-visible
    outline: none
  &-title
    display: flex
    align-items: center
    justify-content: center
    padding: 46px 16px 10px
    font-weight: 700
    color: var(--main)
    p
      margin: 0
      font-size: 1.3rem
      text-align: center
  &-body
    padding: 8px 19px 24px
    text-align: center
    .buttons
      margin-top: 24px
      button
        margin: 0
        &:last-child
          margin-top: 14px
  .close
    width: 30px
    height: 30px
    display: flex
    align-items: center
    justify-content: center
    position: absolute
    top: 8px
    right: 8px
    cursor: pointer
    transition: .15s
    border-radius: 50%
    &:hover
      background: #f3f3f3
