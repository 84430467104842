
.color-picker {
  display: flex;
  align-items: center;
}

.color-picker p {
  margin: 0;
}

.color-picker .colors {
  display: flex;
  margin-left: 8px;
}

.color-picker .colors .color {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: var(--gradient);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.color-picker .colors .color + .color {
  margin-left: 6px;
}
