.event-banner {
  background: var(--gradient);
  border-bottom-left-radius: 16px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding: 44px;
  padding-bottom: 14px;
}

@media (min-width: 576px) {
  .event-banner {
    top: -1px;
    right: -1px;
    left: -1px;
    padding: 40px 140px 50px;
    padding-bottom: 14px;
  }
}

.event-banner p {
  margin: 0;
  font-size: 9px;
  color: #fff;
  font-family: 'Roboto', sans-serif;
}

.event-banner input {
  border: none;
}

.event-banner .emoji,
.event-banner .title {
  margin-bottom: 0;
  position: relative;
}

.event-banner .emoji input,
.event-banner .emoji {
  font-size: 33px;
}

.event-banner .title input,
.event-banner .title input::placeholder,
.event-banner .title {
  color: #fff;
  font-size: 26px;
  font-weight: 800;
  font-family: 'Nunito', sans-serif;
  font-style: normal;
}

.event-body {
  margin-top: 180px;
}

.event-info .info.location {
  cursor: pointer;
}

.event-info .info.date {
  max-width: 216px;
}

.event-info .info.countdown {
  color: var(--main);
  -webkit-text-fill-color: var(--main);
}

.event-banner .back {
  position: absolute;
  top: 10px;
  left: -36px;
  padding: 8px;
  width: 16px;
  height: 16px;
  box-sizing: content-box;
  transition: 0.15s;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
}

.event-banner .share {
  position: absolute;
  top: 10px;
  right: -22px;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.event-banner .back svg * {
  fill: #fff;
}

.event-banner .back svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.event-banner .back:hover {
  background-color: #fff;
}

.event-banner .back:hover svg * {
  fill: var(--main);
}

.event-banner.single {
  padding-bottom: 40px;
}

.event-banner.single .title {
  margin-top: 16px;
}

.event-banner.creation .back {
  left: 100px;
  top: 48px;
}

@media (max-width: 576px) {
  .event-banner.creation .back {
    left: 8px;
  }
}
