.emoji-field {
  position: relative;
}

.emoji-field .emoji-mart {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  left: 0;
  width: auto;
  z-index: 10;
  max-width: 100%;
}

.emoji-field input {
  font-size: 33px;
  border: none;
  outline: none;
  background: none;
  width: 100%;
}