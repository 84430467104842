
.textarea {
  position: relative;
  margin-bottom: 24px;
}

.textarea label {
  font-weight: 700;
  color: var(--textColorSubtitle);
  margin-bottom: 8px;
  display: block;
}

.textarea textarea {
  resize: none;
  border-radius: 6px;
  border: 1px solid var(--borderColor);
  width: 100%;
  outline: none;
  padding: 7px 9px 23px;
  color: var(--textColor);
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, .1) #fff0;
}

.textarea textarea::placeholder {
  color: var(--textColorPlaceholder);
  font-family: 'Roboto', 'Nunito', sans-serif;
  font-style: italic;
}

.textarea .max-length {
  position: absolute;
  bottom: 14px;
  right: 15px;
  font-family: 'Roboto', 'Nunito', sans-serif;
  font-size: 12px;
  color: var(--textColorPlaceholder);
}

.textarea textarea::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, .1);
  border-radius: 6px;
  cursor: pointer;
}
.textarea textarea::-webkit-scrollbar {
  width: 6px;
}

.textarea textarea:hover {
  border-color: var(--textColorSubtitle);
}

.textarea textarea:focus {
  border-color: var(--main);
}