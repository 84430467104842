
.avatar
  border-radius: 50%
  width: 50px
  height: 50px
  overflow: hidden
  img
    height: 100%
    width: 100%
    object-fit: cover
    object-position: center
  .no-image
    width: 100%
    height: 100%
    display: flex
    align-items: center
    justify-content: center
    text-transform: uppercase
    background: #F36F3B
    color: #fff
    font-size: 40px
