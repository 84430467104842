.birth-date {
  border-bottom: 1px solid var(--borderColor) !important;
  padding: 8px 0 !important;
  width: 100%;
  margin-bottom: 8px !important;
  input {
    color: var(--textColor) !important;
    &::placeholder {
      color: var(--textColor) !important;
      font-family: 'Roboto', sans-serif !important;
      font-style: italic !important;
    }
  }
}
