.tag-list {
  margin-bottom: 24px;
}

.tag-list label {
  display: block;
  color: var(--textColorSubtitle);
  font-weight: 700;
  margin-bottom: 7px;
}

.tag-list .tag-items {
  margin: 0 -6px;
}

.tag-list .tag-item,
.tag-list .add {
  padding: 8px 12px;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  display: inline-block;
  color: #fff;
  margin: 0 4px;
  z-index: 1;
  font-family: 'Roboto', 'Nunito', sans-serif;
}

.tag-list .tag-item::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--main);
  z-index: -1;
}

.tag-list.editable .tag-item {
  color: var(--textColor);
  user-select: none;
  cursor: pointer;
  transition: color 0.15s;
}

.tag-list.editable .tag-item::before {
  opacity: 0.15;
  transition: opacity 0.15s;
}

@media not (cursor: coarse) {
  .tag-list.editable .tag-item:hover {
    color: #fff;
  }

  .tag-list.editable .tag-item:hover::before {
    opacity: 0.8;
  }
}

.tag-list.editable .tag-item.active {
  color: #fff;
}
.tag-list.editable .tag-item.active::before {
  opacity: 1;
}

.tag-list .add {
  border: 2px solid var(--main);
  background: #fff;
  color: var(--main);
  cursor: pointer;
  transition: 0.15s;
  padding: 6px 17px;
}

.tag-list .add:hover {
  background: var(--main);
  color: #fff;
}

.tag-list .new-tag input {
  background: none;
  border: none;
  width: fit-content;
  outline: none;
  color: #fff;
  font-size: 1rem;
}
.tag-list .new-tag {
  padding: 6px 6px 6px 17px;
}

.tag-list.editable .tag-item.custom.deletable {
  padding-right: 32px;
}

.tag-list.editable .tag-item.custom .delete {
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.tag-list.editable .tag-item.custom .delete svg {
  width: 70%;
  height: 70%;
}

.tag-list.editable .tag-item.custom .delete svg * {
  fill: #fff;
}

.tag-list.editable .tag-item.custom:not(.active) svg * {
  fill: var(--iconColor);
}

.tag-list.editable .tag-item.custom .delete:hover {
  background: #fff;
}

.tag-list.editable .tag-item.custom .delete:hover svg * {
  fill: var(--main);
}
