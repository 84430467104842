
.dropdown {
  position: relative;
  margin-bottom: 24px;
}

.dropdown label {
  color: var(--textColorSubtitle);
  font-weight: 700;
}

.dropdown select {
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid var(--borderColor);
  padding: 8px 0;
  color: var(--textColor);
  font-size: 1rem;
  width: 100%;
  appearance: none;
  cursor: pointer;
  padding-right: 30px;
  position: relative;
  z-index: 1;
}

.dropdown select option {
  color: var(--textColor);
  font-family: 'Roboto', sans-serif;
  font-style: italic;
}

.dropdown select.not-selected {
  color: var(--textColorPlaceholder);
}

.dropdown::before {
  position: absolute;
  content: '';
  background: url('../../../img/icons/dropdown-arrow.svg');
  top: 40px;
  right: 12px;
  width: 15px;
  height: 9px;
  background-repeat: no-repeat;
  transition: .15s;
  pointer-events: none;
}

.dropdown.focused::before {
  transform: rotate(-180deg);
}

.dropdown.error {
  margin-bottom: 8px !important;
}

.dropdown.error select {
  border-color: var(--danger);
}

.dropdown .error {
  font-size: 11px;
  color: var(--danger);
}
