.button {
  width: 100%;
  padding: 14px;
  background-color: var(--main);
  border-radius: var(--buttonBorderRadius);
  border: 2px solid var(--main);
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  transition: 0.15s;
  margin-bottom: 24px;
  /* max-width: calc(100% - var(--containerPadding) * 2); */
  display: block;
}

.button:hover {
  background: transparent;
  color: var(--main);
}

.button.secondary {
  background-color: #fff;
  color: var(--main);
}

.button.secondary:hover {
  background: var(--main);
  color: #fff;
}

.button:disabled {
  background-color: var(--muted);
  border-color: var(--muted);
  cursor: default;
}

.button:disabled:hover {
  background-color: var(--muted);
  color: #fff;
}

.button.transparentNoBorder {
  background: transparent;
  border: none;
  color: var(--main);
}

.button.bottom {
  position: fixed;
  bottom: 0;
}

@media (min-width: 576px) {
  .button {
    max-width: 375px;
  }
  .button.bottom {
    position: absolute;
  }
}
