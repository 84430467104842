.info {
  margin-left: -16px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  & + .info {
    margin-top: -16px;
  }
  &.past {
    justify-content: flex-start;
    & > * + * {
      margin-left: 28px;
    }
  }
  .info-item {
    padding-left: 18px;
    position: relative;
    color: var(--textColorTitle);
    margin-left: 16px;
    margin-top: 16px;
    font-size: 0.95em;
    &.byoc-gender {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 25px;
      padding: 0;
      svg {
        position: initial;
        left: auto;
        top: auto;
        transform: none;
        height: 100%;
        box-sizing: content-box;
        &:first-child {
          padding-right: 8px;
          border-right: 1px solid #d8d8d8;
        }
        &:last-child {
          padding-left: 8px;
        }
        &.muted {
          filter: grayscale(1);
        }
      }
    }
  }
  svg {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  &.count-down {
    color: var(--main);
  }
}
