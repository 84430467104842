.loading-indicator {
  border: 10px solid var(--main);
  border-radius: 50%;
  border-bottom-color: transparent;
  width: 80px;
  height: 80px;
  animation: loading-indicator 1s infinite linear;
  position: absolute;
  top: 50%;
  left: 50%;
}

@keyframes loading-indicator {
  from {
    transform: translate(-50%, -50%) rotate(0);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
