@mixin toggle(
  $name,
  $toggle-width: 28px,
  $toggle-padding: 4px,
  $toggle-bar-height: $toggle-width + $toggle-padding * 2,
  $toggle-bar-width: $toggle-width * 2 + $toggle-padding * 3 + 2
) {
  height: $toggle-bar-height;
  width: $toggle-bar-width;
  border-radius: 16px;
  position: relative;
  background-color: var(--main);
  cursor: pointer;
  user-select: none;
  transition: 0.15s;
  &::before {
    position: absolute;
    content: '';
    width: $toggle-width;
    height: $toggle-width;
    top: $toggle-padding;
    right: $toggle-padding;
    border-radius: 16px;
    background-color: #fff;
  }
  &[data-enabled='true'] {
    &:before {
      animation: enable-toggle-#{$name} 0.2s backwards;
      animation-iteration-count: 1;
    }
  }
  &[data-enabled='false'] {
    background: var(--muted);
    &:before {
      animation: disable-toggle-#{$name} 0.2s forwards;
      animation-iteration-count: 1;
    }
    span {
      left: auto;
      right: 8px;
    }
  }
  span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 8px;
    font-weight: 500;
    color: #fff;
  }
}

@mixin toggle-keyframes(
  $name,
  $toggle-width: 28px,
  $toggle-padding: 4px,
  $toggle-bar-height: $toggle-width + $toggle-padding * 2,
  $toggle-bar-width: $toggle-width * 2 + $toggle-padding * 3 + 2
) {
  @keyframes disable-toggle-#{$name} {
    0% {
      width: $toggle-width;
      right: $toggle-padding;
    }
    40% {
      width: $toggle-bar-width - $toggle-padding * 2;
      right: $toggle-padding;
    }
    70% {
      width: $toggle-bar-width - $toggle-padding * 2;
      right: $toggle-padding;
    }
    100% {
      width: $toggle-width;
      right: $toggle-width + $toggle-padding * 2 + 2;
    }
  }

  @keyframes enable-toggle-#{$name} {
    0% {
      width: $toggle-width;
      right: $toggle-width + $toggle-padding * 2 + 2;
    }
    40% {
      width: $toggle-bar-width - $toggle-padding * 2;
      right: $toggle-padding;
    }
    70% {
      width: $toggle-bar-width - $toggle-padding * 2;
      right: $toggle-padding;
    }
    100% {
      width: $toggle-width;
      right: $toggle-padding;
    }
  }
}

.toggle {
  @include toggle('default');
}

@include toggle-keyframes('default');
