.container
  height: 100%
  display: flex
  flex-direction: column
  .form
    height: 100%
    display: flex
    flex-direction: column
    justify-content: space-between
    min-height: 85vh

@media (min-width: 576px)
  .container
    .form
      min-height: 70vh
