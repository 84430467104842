.crew-search {
  .search {
    position: relative;
    input {
      border: 1px solid rgba(0, 0, 0, 0.3);
      outline: none;
      padding: 11px 32px;
      width: 100%;
      border-radius: 6px;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: #000000;
      font-family: 'Roboto', sans-serif;
    }
    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 9px;
    }
  }
  .found-users {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    list-style: none;
    li + li {
      margin-top: 28px;
    }
  }
  .invite-phone {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 16px;
    p {
      font-family: 'Roboto';
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: #000000;
      margin-bottom: 0;
    }
    button {
      width: auto;
      padding: 9px 34px;
      margin-top: 24px;
    }
    .invite-button {
      display: flex;
      align-items: center;
      width: auto;
      &.invited {
        pointer-events: none;
      }
      svg {
        margin-right: 4px;
      }
    }
    .invited-note {
      background: rgba(243, 111, 59, 0.1);
      border-radius: 8px;
      display: flex;
      padding: 8px;
      align-items: center;
      margin-top: 20px;
      svg {
        min-width: 15px;
        width: 15px;
        height: 15px;
        margin-right: 10px;
      }
      h4 {
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #f36f3b;
        margin: 0;
      }
      p {
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #000000;
        margin-bottom: 0;
        margin-top: 4px;
      }
    }
  }
}
