
.event-banner
  background: var(--gradient)
  border-bottom-left-radius: 16px
  position: absolute
  top: 0
  right: 0
  left: 0
  padding: 44px
  padding-bottom: 14px
  p
    margin: 0
    font-size: 9px
    color: #fff
    font-family: 'Roboto', sans-serif
  input
    border: none
  .emoji, .title
    margin-bottom: 0
    position: relative
  .emoji input, .emoji
    font-size: 33px
  .title input, .title input::placeholder, .title
    color: #fff
    font-size: 26px
    font-weight: 800
    font-family: 'Nunito', sans-serif
    font-style: normal
  .back
    position: absolute
    top: 10px
    left: -36px
    padding: 8px
    width: 16px
    height: 16px
    box-sizing: content-box
    transition: .15s
    border-radius: 50%
    cursor: pointer
    text-align: center
    svg
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      *
        fill: #fff
    &:hover
      background-color: #fff
      svg *
        fill: var(--main)
  .share
    position: absolute
    top: 10px
    right: -22px
    width: 32px
    height: 32px
    cursor: pointer
  &.single
    padding-bottom: 40px
    .title
      margin-top: 16px
  &.creation
    top: 64px
    .back
      left: 100px
      top: 48px
  &[data-byoc='true']
    background: linear-gradient(134.72deg, #C3EBF3 0%, #7A4DFA 101.5%)

.event-body
  margin-top: 244px
  &.no-margin
    margin-top: 0 !important
  .button
    max-width: 100%

.event-info
  .info
    .location
      cursor: pointer
    .date
      max-width: 216px
    .countdown
      color: var(--main)
      -webkit-text-fill-color: var(--main)

.event-reviews
  margin-bottom: 25px
  margin-top: 25px
  padding-bottom: 32px
  border-bottom: 1px solid #c4c4c4

.event-post-review, .event-reviews
  h1
    font-weight: 700
    font-size: 14px
    line-height: 19px
    color: #2C3E50
    margin-bottom: 16px

.event-post-review
  margin-top: 30px
  .review-textarea
    margin-bottom: 0

@media (min-width: 576px)
  .event-banner
    top: -1px
    right: -1px
    left: -1px
    padding: 40px 140px 50px
    padding-bottom: 14px
    .creation
      top: 64px

@media (max-width: 576px)
  .event-banner.creation
    top: 64px
    .back
      left: 8px
