.overlay
  position: fixed
  z-index: 1000
  top: 0
  left: 0
  right: 0
  bottom: 0
  background: #0007


.image-view 
  display: flex
  align-items: center
  justify-content: center
  height: 100%
  padding: 32px
  .image
    max-width: 100%
    max-height: 95%
  .close
    position: absolute
    top: 32px
    right: 32px
    width: 32px
    height: 32px
    display: flex
    align-items: center
    justify-content: center
    border-radius: 50%
    cursor: pointer
    background: #fff2
    &:hover
      background: #fff7
    // svg
    //   fill
