.container .content-container {
  padding: 48px var(--containerPadding) 15px;
  position: relative;
  min-height: 400px;
  overflow: hidden;
  z-index: 0;
}

.container,
#root {
  position: relative;
}

@media (min-width: 576px) {
  .container {
    background: #f8f8f8;
    padding-bottom: 40px;
    min-height: 100vh;
  }
  .container .content-container {
    max-width: 577px;
    margin: 0 auto;
    padding: 40px 100px;
    background: #fff;
    border: 1px solid var(--desktopContainerBorderColor);
  }
}

.container.preload *,
.container.preload *::before,
.container.preload *::after {
  animation-duration: 0s !important;
}
