.event-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.event-info.start {
  justify-content: flex-start;
}

.event-info.hide {
  display: none;
}

.event-info .info {
  max-width: 100%;
  display: flex;
  align-items: center;
  margin: 0 8px 16px;
}

.event-info .info a {
  color: var(--main);
}

.event-info .info,
.event-info .info * {
  font-family: 'Roboto', sans-serif;
  color: #5c5c5c;
  -webkit-text-fill-color: #5c5c5c;
}

.event-info .info p {
  margin: 0;
}

.event-info .info select {
  padding-top: 0;
  padding-bottom: 0;
  height: 19px;
  border: none;
}

.event-info .info .dropdown {
  margin-bottom: 0;
}

.event-info .info .dropdown::before {
  top: 6px;
}

.event-info .info svg {
  margin-right: 16px;
}
