
.text-input.password {
  position: relative;
}

.text-input.password .visibility {
  position: absolute;
  top: 12px;
  right: 4px;
}

.text-input.password.labeled .visibility {
  top: 32px;
}