.visibility {
  width: 28px;
  height: 18px;
  cursor: pointer;
  user-select: none;
  position: relative;
}

.visibility::before {
  position: absolute;
  content: '';
  width: 32px;
  height: 1px;
  top: -1px;
  right: 0;
  background: var(--textColor);
  transform-origin: right;
  transform: rotate(-30deg) scaleX(1);
  border: 1px solid #fff;
  border-radius: 2px;
  transition: transform .15s;
}

.visibility.visible::before {  
  transform: rotate(-30deg) scaleX(0);
  transform-origin: left;
  bottom: -1px;
  left: -1;
  top: auto;
  right: auto;
}