

.screen-title {
  margin-bottom: 24px;
  position: relative;
  display: flex;
  align-items: center;
}


.screen-title h1 {
  color: var(--main);
  font-size: 2.25rem;
  font-weight: 800;
  line-height: 1;
  margin: 0;
  margin-left: 16px;
}

.screen-title .action {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  color: var(--main);
  cursor: pointer;
  font-weight: 700;
  user-select: none;
}


.screen-title .back {
  cursor: pointer;
  transition: .15s;
  padding: 8px;
  box-sizing: content-box;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  text-align: center;
  transition: .15s;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.screen-title .back svg path,
.screen-title .back svg rect {
  transition: .15s;
}

.screen-title .back:hover {
  background-color: var(--main);
}

.screen-title .back:hover svg path,
.screen-title .back:hover svg rect {
  fill: #fff;
}

.screen-title .back svg.triangle {
  transform: translateX(-2px);
} 