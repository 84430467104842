.event-member {
  display: flex;
  align-items: center;
  height: 50px;
  justify-content: space-between;
  .info {
    display: flex;
    align-items: center;
    padding-right: 20px;
    .name {
      margin-left: 8px;
      text-decoration: underline;
      font-family: 'Roboto', sans-serif;
      color: var(--textColorTitle);
    }
    span {
      margin-left: 16px;
      font-family: 'Roboto', sans-serif;
      color: var(--textColorPlaceholder);
    }
  }
  .actions {
    display: flex;
    & > * + * {
      margin-left: 8px;
    }
    button,
    a {
      width: 40px;
      height: 40px;
      padding: 8px;
      display: flex;
      background: none;
      border: none;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      cursor: pointer;
      transition: 0.15s;
      &:hover {
        background: #f3f3f3;
      }
    }
  }
  & + & {
    margin-top: 10px;
  }
  .avatar-phone {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background: linear-gradient(134.72deg, #c3ebf3 0%, #7a4dfa 101.5%);
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.event-member-byoc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
  .user {
    display: flex;
  }
  .info {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    .name {
      font-size: 16px;
      line-height: 19px;
      text-decoration-line: underline;
      color: #2c3e50;
    }
    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: rgba(0, 0, 0, 0.3);
    }
  }
  .action {
    padding: 4px;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.15s;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: rgb(0, 0, 0, 0.05);
    }
  }
}
