
.image-select {
  width: 30%;
  height: 0;
  padding-bottom: 30%;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
}

.image-select label {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--main);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.image-select label:not(.preview)::before,
.image-select label:not(.preview)::after {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  background: #fff;
  height: 2px;
  width: 32px;
  transform: translate(-50%, -50%);
}

.image-select label:not(.preview)::before {
  transform: translate(-50%, -50%) rotate(90deg); 
}

.image-select label:not(.preview) {
  opacity: 0.2;
  transition: .15s;
}

.image-select label:not(.preview):hover {
  opacity: 1;
}

.image-select .close {
  position: absolute;
  z-index: 1;
  bottom: 8px;
  right: 8px;
  padding: 8px;
  border-radius: 50%;
  background: #fff;
  box-sizing: content-box;
  cursor: pointer;
  opacity: 0;
  transition: .15s;
}

.image-select label.preview:hover + .close {
  opacity: 0.5;
}

.image-select .close:hover {
  opacity: 1;
}

.image-select label:not(.preview) + .close {
  display: none;
}

.crop-modal {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background: #222;
}
.crop-overlay {
  z-index: 1;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(255, 255, 255, 0.75);
}

.crop-modal > img {
  width: 90%;
  position: absolute;
  top: 32px;
  left: 5%;
  display: none;
}

.crop-modal button {
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
}
/* 
.crop-modal-overlay {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
} */