.toggle-labeled
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 16px
  .controls
    display: flex
    justify-content: space-between
    align-items: center
    width: 70%
  &.no-visibility
    .controls
      width: auto
  & > span
    margin-right: 40px
    width: 30%
  &.not-visible .toggle
    opacity: 0.3 !important
