.button
  margin: 0
  position: absolute
  top: 18px
  right: 15px
  border: 2px solid #FFFFFF
  border-radius: 4px
  padding: 9px 12px
  font-weight: 700
  font-size: 12px
  line-height: 15px
  color: #FFFFFF
  background: none
  display: flex
  align-items: center
  justify-content: center
  transition: .15s
  svg
    margin-left: 8px
    transition: .15s
  &:hover
    background: #fff
    color: #34495e
    svg *
      fill: #34495e
