.datetime-input input,
.datetime-input button {
  padding: 0;
}

.datetime-input button svg.MuiSvgIcon-root {
  margin-right: 0;
}

.datetime-input fieldset {
  border: none;
}

.datetime-input .MuiButtonBase-root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.datetime-input .MuiButtonBase-root:hover {
  background-color: transparent;
}

.datetime-input .MuiButtonBase-root svg,
.datetime-input .MuiTouchRipple-root {
  display: none;
}
