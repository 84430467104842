html {
  --main: #f36f3b;
  --textColor: #666666;
  --textColorTitle: #2c3e50;
  --textColorSubtitle: #34495e;
  --textColorPlaceholder: rgba(0, 0, 0, 0.3);
  --muted: #d8d8d8;
  --buttonBorderRadius: 60px;
  --borderColor: #979797;
  --desktopContainerBorderColor: rgba(52, 73, 94, 0.3);
  --iconColor: #3f3f3f;
  --overlayBackgroundColor: rgba(0, 0, 0, 0.5);
  --danger: #f22e34;
  --containerPadding: 15px;
  font-size: 16px;
}

body {
  margin: 0;
}

* {
  font-family: 'Nunito', 'Roboto', 'Segoe UI', sans-serif;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: var(--main);
}

@media (hover: none) {
  * {
    -webkit-tap-highlight-color: transparent;
  }
}

a.nav-link {
  color: var(--textColorSubtitle);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

a.nav-link svg {
  margin-right: 4px;
}

.muted {
  color: var(--muted);
}

.main-color {
  fill: var(--main);
}

.swiper-slide {
  height: auto;
}

html,
body,
#root {
  height: 100%;
}
