

.icon-button {
  fill: var(--iconColor);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid var(--iconColor);
  padding: 14px;
  cursor: pointer;
  transition: .15s;
}

.icon-button:hover {
  fill: #fff;
  background-color: var(--main);
  border-color: var(--main);
}