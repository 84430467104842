.byoc-gender {
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #5c5c5c;
    margin-bottom: 8px;
  }
  .options {
    display: flex;
    .option {
      border: 1px solid rgba(0, 0, 0, 0.3);
      border-radius: 6px;
      padding: 4px 10px 4px 12px;
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #2c3e50;
        margin-left: 8px;
        margin-right: 4px;
      }
      .check {
        width: 12px;
        height: 12px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 50%;
        position: relative;
        top: -1px;
        transition: 0.15s;
        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #f36f3b;
          opacity: 0;
          transition: 0.15s;
        }
      }
      & + .option {
        margin-left: 28px;
      }
      &[data-checked='true'] {
        .check {
          border-color: #f36f3b;
          &::before {
            opacity: 1;
          }
        }
        &[data-gender='female'] {
          border-color: #f4648b;
        }
        &[data-gender='male'] {
          border-color: #0128af;
        }
      }
    }
  }
}
