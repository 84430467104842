.radio-select
  display: flex
  flex-direction: column
  .option
    padding: 16px 18px
    font-weight: 400
    font-size: 16px
    line-height: 19px
    color: #2C3E50
    font-family: 'Roboto'
    background: #FFFFFF
    border: 1px solid #D8D8D8
    border-radius: 6px
    display: flex
    align-items: center
    transition: .15s
    cursor: pointer
    & + .option
      margin-top: 8px
    .radio-view
      width: 24px
      height: 24px
      display: inline-block
      border-radius: 50%
      border: 1px solid #C8C8C8
      margin-right: 10px
      position: relative
      &:before
        position: absolute
        content: ''
        width: 16px
        height: 16px
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        background: #F36F3B
        border-radius: 50%
        transition: .15s
        opacity: 0
    &:hover
      border-color: #F36F3B
    &[data-active='true']
      border-color: #F36F3B
      .radio-view:before
        opacity: 1
