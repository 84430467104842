.chat-link > * {
  display: block;
  font-family: 'Roboto', sans-serif;
}

.chat-link {
  margin-top: 24px;
  /* margin-bottom: 50px; */
}

.chat-link .title {
  font-weight: 700;
  color: var(--textColorTitle);
}
.chat-link span {
  color: var(--textColorPlaceholder);
  margin-bottom: 8px;
}
.chat-link a {
  display: flex;
  align-items: center;
  color: var(--textColorTitle);
}
.chat-link a svg {
  margin-right: 8px;
}

.chat-link a:hover {
  color: var(--main);
}

.chat-link a.button {
  color: #fff;
  justify-content: center;
  font-weight: 700;
  margin-top: 24px;
}

.chat-link a.button:hover {
  color: var(--main);
}
