.invitee-card
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05)
  border-radius: 24px
  background: linear-gradient(133.56deg, rgba(186, 255, 243, 0.2) 1.88%, rgba(243, 111, 59, 0.2) 103.65%)
  &+&
    margin-top: 24px
  .header
    display: flex
    justify-content: space-between
    align-items: center
    border-radius: 24px 24px 0 0
    padding: 8px 16px 8px
    cursor: pointer
    .left-side
      display: flex
      align-items: center
    .avatar
      max-width: 62px
      min-width: 62px
      width: 62px
      max-height: 62px
      min-height: 62px
      height: 62px
      border-radius: 50%
      overflow: hidden
      img
        width: 100%
        height: 100%
        object-fit: cover
        object-position: center
      .no-image
        width: 100%
        height: 100%
        display: flex
        align-items: center
        justify-content: center
        text-transform: uppercase
        background: #F36F3B
        color: #fff
        font-size: 40px
    .info
      margin-left: 14px
      color: #2C3E50
      .name
        text-decoration: underline
        font-size: 18px
        line-height: 21px
        overflow-wrap: anywhere
        .gender
          margin-left: 8px
      .age
        font-size: 12px
        line-height: 14px
        margin-top: 6px
        svg
          height: 9px
          width: 9px
          margin-right: 2px
    .invite-button
      display: flex
      align-items: center
      line-height: 1
      padding: 4px 34px
      width: auto
      margin: 0
      &.invited
        padding: 4px 19px
        pointer-events: none
        svg
          margin-right: 4px

  .content
    background: rgba(255, 255, 255, 0.6)
    backdrop-filter: blur(2.5px)
    padding: 24px 2px 14px 9px
    border-radius: 0 0 24px 24px
    .about
      margin: 0
      margin-top: 7px
      font-size: 14px
      line-height: 16px
      color: #666666

.features
  display: flex
  flex-wrap: wrap
  margin: -4px
  .feature
    padding: 7px 14px
    border-radius: 50px
    background: rgba(255, 255, 255, 0.5)
    border: 2px solid #F36F3B
    font-size: 13px
    line-height: 16px
    color: #000000
    margin: 4px
    line-height: 1
    &.expand
      user-select: none
      cursor: pointer
      transition: .15s
      &:hover
        color: #fff
        background: #F36F3B
