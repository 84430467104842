
.review
  display: flex
  margin-top: 16px
  .avatar
    min-width: 50px
  .content
    background: #FEF1EB
    border-radius: 10px
    padding: 10px 12px
    margin-left: 6px
    h1
      font-family: 'Roboto'
      font-weight: 400
      font-size: 16px
      line-height: 19px
      text-decoration-line: underline
      color: #2C3E50
      margin: 0
    p
      margin: 0
      font-family: 'Roboto'
      font-weight: 400
      font-size: 14px
      line-height: 16px
      color: #2C3E50
      margin-top: 5px
