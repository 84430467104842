

.checkbox-labeled {
  display: flex;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  color: var(--textColor);
  font-size: 0.875rem;
  line-height: 1rem;
  margin-bottom: 24px;
}

.checkbox-labeled .checkbox {
  margin-right: 8px;
}

.checkbox-labeled > *:not(label),
.checkbox-labeled > label > * {
  margin: 0;
}