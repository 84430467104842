@import '../../../assets/css/components/controls/Toggle.scss';

.toggle {
  @include toggle('small', 18px, 3px);
}

@include toggle-keyframes('small', 18px, 3px);

.filter {
  display: flex;
  margin-bottom: 20px;
  .toggle-container {
    display: flex;
  }
  .toggle + span {
    margin-left: 8px;
  }
  .age-controls {
    display: flex;
    margin-left: 40px;
    span {
      margin-left: 8px;
      margin-right: 8px;
      font-size: 16px;
      color: #2c3e50;
    }
  }
}

.age-scroller {
  width: 42px;
  position: relative;

  &[data-error='true'] {
    input {
      border-color: var(--danger);
    }
  }

  input {
    width: 100%;
    max-width: 100%;

    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    outline: none;

    text-align: center;
    font-size: 14px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.5);

    padding: 5px;

    transition: 0.15s;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type='number'] {
      -moz-appearance: textfield;
      appearance: textfield;
    }
  }

  .options {
    width: 100%;
    padding: 5px;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 6px;

    position: absolute;

    height: 106px;
    overflow: hidden;

    top: -40px;

    background-color: #fff;

    transition: 0.1s;

    &[data-visible='false'] {
      visibility: hidden;
      opacity: 0;
    }

    .wrapper {
      margin-top: 40px;
    }

    .option {
      text-align: center;
      font-size: 14px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.5);
      transition: 0;
      & + .option {
        margin-top: 4px;
      }
      &[data-current='true'] {
        transform: scale(1.2);
        color: rgba(0, 0, 0, 0.8);
        transition: 0.1s;
        font-weight: 600;
      }
      &[data-disabled='true'] {
        opacity: 0.3;
      }
    }
  }
}

.event-type {
  height: 64px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 20px;
  .back {
    position: absolute;
    left: 8px;
    padding: 10px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
  }
  span {
    font-family: 'Nunito', 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #2c3e50;
    cursor: pointer;
    border-bottom: 1px solid transparent;
    transition: 0.15s;
    user-select: none;
    &:hover,
    &.current {
      border-color: #f36f3b;
    }
  }
}

.edit {
  margin-top: 180px !important;
  .banner {
    top: 0 !important;
  }
}

.byoc-note {
  background: rgba(243, 111, 59, 0.1);
  border-radius: 8px;
  display: flex;
  padding: 16px 9px 16px 12px;
  align-items: center;
  margin-bottom: 12px;
  position: relative;
  cursor: pointer;
  p {
    font-family: 'Roboto';
    font-weight: 400;
    color: #000000;
    margin: 0;
  }
  .close {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 4px;
    right: 4px;
    svg {
      width: 8px;
      height: 8px;
    }
  }
}
