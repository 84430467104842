.invite-card
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05)
  border-radius: 16px
  background: linear-gradient(133.56deg, #F2FEFB 1.88%, #F7DCD2 103.65%)
  position: relative
  .avatar
    max-width: 45px
    min-width: 45px
    width: 45px
    max-height: 45px
    min-height: 45px
    height: 45px
    border-radius: 50%
    overflow: hidden
    margin-right: 10px
    img
      width: 100%
      height: 100%
      object-fit: cover
      object-position: center
    .no-image
      width: 100%
      height: 100%
      display: flex
      align-items: center
      justify-content: center
      text-transform: uppercase
      background: #F36F3B
      color: #fff
      font-size: 40px
  .unread
    position: absolute
    top: -2px
    right: -2px
    width: 12px
    height: 12px
    border-radius: 50%
    background: #FF1212
  &+&
    margin-top: 16px
  .header
    display: flex
    align-items: center
    border-radius: 16px 16px 0 0
    padding: 12px
    cursor: pointer
    .name
      font-size: 18px
      line-height: 21px
      text-decoration-line: underline
      color: #2C3E50
      margin-right: 10px
      margin-left: 10px
      font-family: 'Roboto'
    .name + span
      font-weight: 700
      font-size: 16px
      line-height: 22px
      color: #2C3E50
      font-family: 'Nunito'

  .content
    background: rgba(255, 255, 255, 0.6)
    backdrop-filter: blur(2.5px)
    padding: 12px 12px 20px
    border-radius: 0 0 16px 16px
    p
      margin: 0
    h1
      font-family: 'Roboto'
      font-weight: 500
      font-size: 18px
      line-height: 21px
      color: #2C3E50
      margin: 12px 0
    .card-info
      margin: 0

  &.preview
    margin-bottom: 16px
    position: relative
    cursor: pointer
    &.multiple
      margin-bottom: 32px
      &:before
        position: absolute
        content: ''
        background: linear-gradient(133.56deg, rgba(186, 255, 243, 0.2) 1.88%, rgba(243, 111, 59, 0.2) 103.65%)
        border-radius: 16px
        width: 100%
        height: calc(100% - 8px)
        top: 16px
        left: 8px
        z-index: -1
    .header
      padding: 14px
      h1
        font-family: 'Nunito'
        font-weight: 700
        font-size: 16px
        line-height: 22px
        color: #2C3E50
        margin: 0 12px
        line-height: 1
    .content
      display: flex
      .user-info
        display: flex
        flex-direction: column
        align-items: center
      .avatar
        max-width: 32px
        min-width: 32px
        width: 32px
        max-height: 32px
        min-height: 32px
        height: 32px
        .no-image
          font-size: 24px
      .name
        margin-top: 4px
        font-size: 12px
        line-height: 14px
        color: #2C3E50
        font-family: 'Roboto'
      .event-info
        flex-grow: 1
        margin-left: 24px
        h2
          font-family: 'Roboto'
          font-weight: 500
          font-size: 16px
          line-height: 19px
          color: #2C3E50
          margin: 6px 0
        .content-top
          display: flex
          justify-content: space-between
          .invite-time
            font-family: 'Roboto'
            font-size: 12px
            line-height: 14px
            color: #2C3E50
        .event-start
          display: flex
          align-items: center
          font-family: 'Roboto'
          font-size: 12px
          line-height: 14px
          color: #2C3E50
          svg
            margin-right: 5px
