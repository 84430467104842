.bottom-navigation {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 70px;
  background: url('../../../../img/menu-background.svg');
  z-index: 1;
  max-width: 375px;
  width: 100%;
  background-size: cover;
}

.bottom-navigation .main-button {
  position: absolute;
  background: var(--main);
  border-radius: 50%;
  width: 64px;
  height: 64px;
  top: 0;
  left: 50%;
  transform: translate(-50%, -60%);
  cursor: pointer;
  border: 3px solid var(--main);
  transition: background-color 0.15s;
}

.bottom-navigation .main-button::before,
.bottom-navigation .main-button::after {
  position: absolute;
  content: '';
  width: 32px;
  height: 3px;
  border-radius: 2px;
  background: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: background-color 0.15s;
}
.bottom-navigation .main-button::after {
  transform: translate(-50%, -50%) rotate(90deg);
}

.bottom-navigation .main-button:hover {
  background: #fff;
}
.bottom-navigation .main-button:hover::before,
.bottom-navigation .main-button:hover::after {
  background: var(--main);
}

.bottom-navigation .nav-button {
  position: absolute;
  top: 12px;
  padding: 8px;
  width: 40px;
  height: 44px;
  overflow: hidden;
}

.bottom-navigation .nav-button svg {
  width: 23px;
}

.bottom-navigation .nav-button.home {
  left: 40px;
}
.bottom-navigation .nav-button.profile {
  right: 40px;
}

.bottom-navigation .nav-button::before {
  position: absolute;
  content: '';
  background: var(--main);
  width: 23px;
  height: 3px;
  transition: 0.15s;
  left: 50%;
  transform: translateX(-50%) scaleX(0);
  bottom: 0;
  border-radius: 2px;
}

.bottom-navigation .nav-button:hover::before,
.bottom-navigation .nav-button.active::before {
  transform: translateX(-50%) scaleX(1);
}

.bottom-navigation .nav-button svg * {
  fill: var(--main);
}

.bottom-navigation .nav-button svg {
  opacity: 0.25;
}

.bottom-navigation .nav-button.active svg {
  opacity: 1;
}

.bottom-navigation .nav-button svg {
  filter: drop-shadow(0px 0px 5px rgba(243, 111, 59, 0.5));
}

.bottom-navigation .nav-button::before {
  box-shadow: 0px 0px 5px rgba(243, 111, 59, 0.5);
}

@media (max-width: 576px) {
  .bottom-navigation {
    max-width: none;
    position: fixed;
    bottom: -2px;
  }
}
