

.basic-info {
  margin-bottom: 24px;
}

.basic-info .label {
  margin-bottom: 8px;
  font-weight: 700;
  color: var(--textColorSubtitle)
}

.basic-info .list-container {
  max-width: 100%;
  position: relative;
  height: 22px;
  overflow: hidden;
}

.basic-info .list-container .list {
  position: absolute;
  top: 0;
  left: 0;
  width: 1000px;
  user-select: none;
}

.basic-info .list-container .list .list-item {
  display: inline-block;
  padding: 0 8px;
}

.basic-info .list-container .list .list-item .icon {
  margin-right: 8px;
}

.basic-info .list-container .list .list-item + .list-item {
  border-left: 1px solid var(--textColorTitle);
}

.basic-info .list-container::before,
.basic-info .list-container::after {
  content: '';
  position: absolute;
  z-index: 1;
  filter: blur(.9px);
  width: 5px;
  height: 100%;
  background: #fff;
  top: 0;
}

.basic-info .list-container::before {
  left: -3px;
}
.basic-info .list-container::after {
  right: -3px;
}

@media (max-width: 576px) {
  .basic-info .list-container {
    overflow-x: scroll;
    height: 30px;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .basic-info .list-container::-webkit-scrollbar {
    display: none;
  }
}