
.otp-input {
  margin-bottom: 24px;
  transition: opacity .15s;
}

.otp-input label {
  color: var(--textColorSubtitle);
  font-weight: 700;
  margin-bottom: 8px;
}

.otp-input .inputs {
  display: flex;
}

.otp-input .inputs input {
  flex: 1 1 auto;
  width: 0;
  margin: 0 9px;
  padding: 4px 8px;
  appearance: none;
  -moz-appearance: textfield;
  border: none;
  border-bottom: 1px solid var(--borderColor);
  text-align: center;
  outline: none;
  font-size: 1rem;
}

.otp-input .inputs input::-webkit-outer-spin-button,
.otp-input .inputs input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otp-input .inputs input:first-child {
  margin-left: 0;
}
.otp-input .inputs input:last-child {
  margin-right: 0;
}

.otp-input .action {
  margin-top: 16px;
  color: var(--main);
  font-weight: 700;
  user-select: none;
}

.otp-input .action span {
  cursor: pointer;
}

.otp-input.disabled {
  pointer-events: none;
  opacity: 0.4;
  user-select: none;
}

.otp-input .action .button {
  margin-top: 8px;
}