.invited-user {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  background: linear-gradient(134.72deg, #c3ebf3 0%, #7a4dfa 101.5%);
  margin-left: 16px;
  position: relative;
  .close {
    position: absolute;
    top: 0;
    right: -6px;
    cursor: pointer;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
  }
}
