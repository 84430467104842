.join-byoc {
  .title {
    display: flex;
    align-items: center;
    h1 {
      font-family: 'Nunito';
      font-weight: 800;
      font-size: 36px;
      line-height: 49px;
      color: #f36f3b;
      margin: 0;
      margin-left: 16px;
    }
    .back {
      padding: 8px;
      cursor: pointer;
    }
  }
  .join-button {
    margin-top: 100px;
  }
}
