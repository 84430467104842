.section-label
  font-size: 18px
  line-height: 25px
  color: #F36F3B
  margin: 16px 0

.location-denied
  h3
    font-weight: 700
    font-size: 20px
    line-height: 27px
    text-align: center
    font-family: 'Nunito'
    color: #F36F3B
  p
    font-weight: 400
    font-size: 16px
    line-height: 19px
    text-align: center
    color: #2C3E50
