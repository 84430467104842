.desktop-header {
  height: 40px;
  border-bottom: 1px solid var(--desktopContainerBorderColor);
  padding-left: 200px;
  margin-bottom: 40px;
}
.desktop-header svg.logo {
  height: 100%;
}

@media (max-width: 575.9px) {
  .desktop-header {
    display: none;
  }
} 