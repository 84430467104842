

.text-input.phone .action {
  position: absolute;
  bottom: 8px;
  right: 4px;
  cursor: pointer;
  user-select: none;
  color: var(--main);
  font-weight: 700;
}

.text-input.phone .action::before {
  position: absolute;
  content: '';
  bottom: 0;
  right: 0;
  width: 100%;
  height: 2px;
  background-color: var(--main);
  transform-origin: right;
  transform: scaleX(0);
  transition: transform .15s;
}

.text-input.phone .action:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}