

.nav-list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 16px;
  flex-direction: column;
  border-bottom: 1px solid var(--borderColor);
  border-top: 1px solid var(--borderColor);
}

.nav-list li a {
  color: var(--textColorSubtitle);
  line-height: 22px;
  padding: 8px 0;
  display: inline-block;
  width: 100%;
}

.nav-list li + li {
  border-top: 1px solid var(--borderColor);
}