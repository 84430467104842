

.checkbox input {
  display: none;
}

.checkbox label {
  display: block;
  width: 21px;
  height: 21px;
  border-radius: 2px;
  border: 1px solid var(--borderColor);
  cursor: pointer;
  background: no-repeat center;
  background-color: #F5F5F5;
  transition: .1s;
}

.checkbox input:checked + label {
  background-image: url('../../../img/icons/tick.svg');
  background-color: var(--main);
  border-color: var(--main);
}