.text-input {
  margin-bottom: 24px;
  position: relative;
}

.text-input label {
  color: var(--textColorSubtitle);
  font-weight: 700;
}

.text-input input {
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid var(--borderColor);
  padding: 8px 0;
  color: var(--textColor);
  font-size: 1rem;
  width: 100%;
}

.text-input input::placeholder {
  color: var(--textColorPlaceholder);
  font-family: 'Roboto', sans-serif;
  font-style: italic;
}

.text-input.error {
  margin-bottom: 8px;
}

.text-input.error input {
  border-color: var(--danger);
}

.text-input .error {
  font-size: 11px;
  color: var(--danger);
  text-align: start;
}

.text-input .tip {
  font-size: 0.7em;
  color: var(--textColor);
}

.text-input.disabled {
  opacity: 0.6;
}
