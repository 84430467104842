.invited-note {
  background: rgba(243, 111, 59, 0.1);
  border-radius: 8px;
  display: flex;
  padding: 8px;
  align-items: center;
  margin-bottom: 20px;
  svg {
    min-width: 15px;
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }
  h4 {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #f36f3b;
    margin: 0;
  }
  p {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
    margin-bottom: 0;
    margin-top: 4px;
  }
}

.pending-byoc {
  display: flex;
  align-items: center;
  width: fit-content;
  background: #8b8b8b;
  padding: 6px 8px 5px 7px;
  font-family: 'Nunito';
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  border-radius: 6px;
  margin-bottom: 20px;
  svg {
    margin-right: 6px;
  }
}

.byoc-members {
  margin-bottom: 10px;
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 24px;
    .icons {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      z-index: 1;
      padding: 0 9px;
    }
    svg:not(:first-child) {
      margin-left: 8px;
    }
    &::before {
      position: absolute;
      content: '';
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: 1px;
      background: rgba(0, 0, 0, 0.3);
      right: 0;
    }
  }
  .members {
    display: flex;
    .crew {
      width: 50%;
      padding: 0 4px;
      .add {
        display: flex;
        align-items: center;
        font-family: 'Nunito';
        font-weight: 700;
        font-size: 16px;
        line-height: 25px;
        color: #f36f3b;
        cursor: pointer;
        padding: 8px 0;
        svg {
          margin-right: 12px;
        }
      }
    }
  }
}

.byoc-genders {
  display: flex;
  align-items: center;
  justify-content: center;
  .icon {
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:first-child {
      border-right: 1px solid #d8d8d8;
    }
  }
  svg {
    margin: 0 !important;
    &.muted {
      filter: grayscale(1);
      opacity: 0.5;
    }
  }
}
