.controls-group {
  margin-bottom: 24px;
}

.controls-group label {
  font-weight: 700;
  margin-bottom: 8px;
  color: var(--textColorSubtitle);
  display: block;
}

.controls-group .text-input {
  margin-bottom: 8px;
}

.controls-group > *:last-child {
  margin-bottom: 0;
}

.controls-group > label + .toggle-labeled {
  margin-top: 16px;
}

.controls-group .text-input.error {
  margin-bottom: -6px;
}