.event-location-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.event-location-modal .modal-map {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.event-location-modal input,
.event-location-modal button.button {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  max-width: 80%;
}

.event-location-modal input {
  top: 20px;
  background: #fffa;
  outline: none;
  border: none;
  border-bottom: 1px solid var(--borderColor);
  font-size: 1.5rem;
  padding: 4px 8px;
  width: 100%;
  text-align: center;
}

.event-location-modal button.button {
  bottom: 20px;
}

@media (min-width: 576px) {
  .event-location-modal input,
  .event-location-modal button.button {
    max-width: 375px;
  }
}