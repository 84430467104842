

.login-container {
  text-align: center;
  padding-top: 24px;
}

.login-container .logo {
  margin-bottom: 50px;
}

.login-container .login-socials {
  margin-top: 36px;
  margin-bottom: 70px;
}

.login-container .login-socials p {
  margin-top: 0;
  margin-bottom: 24px;
}

.login-container .login-socials svg + svg {
    margin-left: 28px;
}

.login-container button[type=submit] {
  margin-bottom: 0;
}

@media (min-width: 576px) {
  .login-container {
    padding-top: 0;
  }
  .login-container .logo {
    margin-bottom: 36px;
  }
  .login-container .login-socials {
    margin-top: 24px;
    margin-bottom: 50px;
  }
}