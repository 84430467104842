.search-user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left {
    display: flex;
    .info {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      p {
        margin: 0;
      }
      .name {
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        text-decoration-line: underline;
        color: #2c3e50;
        margin-bottom: 6px;
        svg {
          margin-left: 9px;
        }
      }
      .age {
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #2c3e50;
        svg {
          margin-right: 4px;
        }
      }
    }
  }
  .invite-button {
    display: flex;
    align-items: center;
    line-height: 1;
    padding: 4px 34px;
    width: auto;
    margin: 0;
    &.invited {
      padding: 4px 19px;
      pointer-events: none;
    }
    svg {
      margin-right: 4px;
    }
  }
}
