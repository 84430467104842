
$gap: 6px

.instagram-block
  .instagram-slide
    display: flex
    justify-content: flex
    flex-wrap: wrap
    margin-bottom: -$gap
    .instagram-image
      width: 100%
      height: 100%
      object-fit: cover
      object-position: center
      position: absolute
      &-wrapper
        width: calc(100% / 3 - #{$gap} * 2 / 3)
        cursor: pointer
        padding-bottom: $gap
        position: relative
        padding-bottom: calc(100% / 3 - #{$gap} * 2 / 3)
        margin-bottom: $gap
        &:not(:nth-child(3n))
          margin-right: $gap
  .pagination
    display: flex
    justify-content: center
    padding: 14px
    .bullet
      width: 7px
      height: 7px
      border-radius: 50%
      background: var(--main)
      opacity: 0.3
      display: block
      transition: .15s
      cursor: pointer
      &:not(:first-child)
        margin-left: 10px
      &.active
        opacity: 1
      &:hover
        opacity: 0.7
